import React, {Component} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import * as PropTypes from "prop-types";
import SEO from "../components/seo";
import Gallery from "../components/gallery";
import {Col, Row} from "antd";

export default class ContactsPage extends Component {
    render() {

        let {data} = this.props; // this prop will be injected by the GraphQL query below.
        const {markdownRemark, } = data; // data.markdownRemark holds our post data
        const {html} = markdownRemark.childMarkdownRemark;

        return (
            <Layout isAbout={true}>
                <SEO title="Контакты"/>

                <div style={{marginBottom: "35px"}}>АРХИТЕКТУРНОЕ БЮРО NERPA</div>

                <div
                    style={{lineHeight: "0.7em" }}
                    dangerouslySetInnerHTML={{__html: html}}
                />

                <br/>

                <div>Санкт-Петербург, ул. Марата 20</div>
                <div><a href="mailto:mail@nerpadesign.com">mail@nerpadesign.com</a></div>
                <div><a href="tel:+7 921 341 43 08">+7 (921) 341 43 08</a></div>
                {/*<div><a href="tel:+7 921 371 24 50">+7 (921) 371 24 50</a></div>*/}
                {/*<div><a href="tel:+7 931 306 01 98">+7 (931) 306 01 98</a></div>*/}

                <br/>
                <br/>

                {data.gallery.edges.length === 0 ? <></> :
                    <Row gutter={[0]}>
                        <Col md={16} xs={24}>
                            <Gallery
                                images={data.gallery.edges
                                    .map((image, idx) => ({
                                        id: idx,
                                        ...image.node.childImageSharp.fluid
                                    }))}
                                itemsPerRowByBreakpoints={[3, 3]}
                            />
                        </Col>
                    </Row>
                }

                <br/><br/>

            </Layout>
        )
    }
}

ContactsPage.propTypes = {data: PropTypes.any};

export const pageQuery = graphql`
    query {
        markdownRemark: file(
            sourceInstanceName: {eq: "contacts"},
            ext: {eq: ".md"})
        {
            childMarkdownRemark {
                html
            }
        }
        
        gallery: allFile(filter: { extension: {regex: "/(jpg)|(jpeg)|(png)/"},
            sourceInstanceName: {eq: "contacts"}},
            sort: { order: ASC, fields: [name] }) {
            edges {
                node {
                    name
                    sourceInstanceName
                    childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;